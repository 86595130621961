import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-existing-home',
	templateUrl: './existing-home.component.html',
	styleUrls: [ './existing-home.component.scss' ]
})
export class ExistingHomeComponent implements OnInit {
	constructor(private titleService: Title, private metaService: Meta) {}

	ngOnInit(): void {
		this.titleService.setTitle('M&D Electrical Solutions, LLC - Existing Home');
		this.metaService.addTags([
			{ name: 'keywords', content: 'Electrician, Existing Home, Planning, Remodeling, Electrical, Generators' },
			{
				name: 'description',
				content:
					'Our experts are here to help trouble shoot issues, replace fuses and breakers, provide power to pools, spas, hot tubs, add additional outlets and much more.'
			},
			{ name: 'robots', content: 'index, follow' }
		]);
	}
}
