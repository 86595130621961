import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-affiliations',
	templateUrl: './affiliations.component.html',
	styleUrls: [ './affiliations.component.scss' ]
})
export class AffiliationsComponent implements OnInit {
	constructor(private titleService: Title, private metaService: Meta) {}

	ngOnInit(): void {
		this.titleService.setTitle('M&D Electrical Solutions, LLC - Affiliations');
		this.metaService.addTags([
			{
				name: 'keywords',
				content: 'Affliations, Pierceton, Indiana, BANCI, Pierceton Chamber of Commerce'
			},
			{
				name: 'description',
				content:
					'When we work together we can achieve more! Below are a list of our affiliations that help make our mission a success!'
			},
			{ name: 'robots', content: 'index, follow' }
		]);
	}
}
