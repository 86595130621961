import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-testimonials',
	templateUrl: './testimonials.component.html',
	styleUrls: [ './testimonials.component.scss' ]
})
export class TestimonialsComponent implements OnInit {
	constructor(private titleService: Title, private metaService: Meta) {}

	ngOnInit(): void {
		this.titleService.setTitle('M&D Electrical Solutions, LLC - Reviews');
		this.metaService.addTags([
			{ name: 'keywords', content: 'Testimonials, Reviews, 5 Stars, Great Feedback, Terrific Customers' },
			{
				name: 'description',
				content:
					'Together we have a great working relationship with and are always happy to hear from our customers on how well we did the job.'
			},
			{ name: 'robots', content: 'index, follow' }
		]);
	}
}
