<app-content-card>
    <img src="assets/imgs/generators.jpg">
    <div>
        <h1><b>Your Power Pro Elite+ Generac Pro Dealer</b></h1>
        <p>
            We are your local Authorized Generac Dealer and Service Installer with over 10 years’ experience with Generac products.
            We have reached the Power Pro Elite+ status and are able to provide Sales, Service, Repair, and Financing on our Generac products to you.
            We work hard to ensure that you get the right generator at the right cost. From the smaller air-cooled units for homeowners to our 
            larger liquid cooled units for commercial use. We have them all! Don’t stress over losing power ever again. With a Generac whole home standby generator, 
            you will never be left in the dark again.
        </p>
        <div>
            <a class="highlight-link" href="/services/generac/generators/residential">Needing Partial or Whole Home Generator?</a>
          </div>
          <div>
            <a class="highlight-link" href="/services/generac/generators/commercial">Needing a Commercial Generator?</a>
          </div>    
    </div>
    <div>
        <b>Reasons to Own a Generac Generator</b>
        <ul>
            <li>Never be left in the dark again</li>
            <li>Keep your critical appliances running so your food doesn’t spoil</li>
            <li>Stay cool or warm based on the season by keeping the AC and Heat powered up</li>
            <li>Ensure that your security systems stay up and always running</li>
            <li>Minimizes Business downtown</li>
            <li>Avoid flooding damage as your sump pump will continue to run</li>
            <li>Have peace of mind that your home is powered and protected</li>
        </ul>
    </div>
</app-content-card>
