import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AffiliationsComponent } from './affiliations/affiliations.component';
import { CommercialServicesComponent } from './commercial-services/commercial-services.component';
import { ElectricalComponent } from './electrical/electrical.component';
import { ExistingHomeComponent } from './existing-home/existing-home.component';
import { GeneratorCommercialComponent } from './generator-commercial/generator-commercial.component';
import { GeneratorResidentialComponent } from './generator-residential/generator-residential.component';
import { GeneratorsComponent } from './generators/generators.component';
import { HomeComponent } from './home/home.component';
import { PayOnlineComponent } from './pay-online/pay-online.component';
import { PlanningNewHomeComponent } from './planning-new-home/planning-new-home.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent
	},
	{
		path: 'affiliations',
		component: AffiliationsComponent
	},
	{
		path: 'reviews',
		component: TestimonialsComponent
	},
	{
		path: 'pay-online',
		component: PayOnlineComponent
	},
	{
		path: 'services/electrical',
		component: ElectricalComponent
	},
	{
		path: 'services/electrical/new-home',
		component: PlanningNewHomeComponent
	},
	{
		path: 'services/electrical/update-home',
		component: ExistingHomeComponent
	},
	{
		path: 'services/electrical/commercial',
		component: CommercialServicesComponent
	},
	{
		path: 'services/generac/generators',
		component: GeneratorsComponent
	},
	{
		path: 'services/generac/generators/residential',
		component: GeneratorResidentialComponent
	},
	{
		path: 'services/generac/generators/commercial',
		component: GeneratorCommercialComponent
	}
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
