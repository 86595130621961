<div class="frame">
  <!-- Toolbar -->
  <div class="toolbar" role="banner">
    <a href="/" style="text-decoration: none;display: flex;align-items: center;">
      <img alt="M&D Electrical Solutions, LLC Logo" class="logo" src="assets/imgs/logo.png" />
      <div class="vertical-line-container" style="padding-left:.5rem;padding-right:.5rem">
        <div class="vertical-spacer" style="height: 60px;width:1px;background-color: #b0b0b0;">&nbsp;</div>
      </div>
      <img alt="M&D Electrical Solutions, LLC Generac Elite Plus Dealer Logo" class="generac-logo" src="assets/imgs/generac-power-pro-elite-plus-small.jpg" />
    </a>
    <div class="spacer"></div>
    <div style="text-align: center;">
      <div>
        <a href="https://www.facebook.com/MDElectricalSolutions"><img class="social" alt="Visit Our Facebook Page" src="assets/imgs/facebook.png"></a>
        <a href="tel:+15745515412" style="margin-left: 1rem;"><img class="contact" alt="Call us today" src="assets/imgs/phone.png"></a>
      </div>
    </div>
  </div>

  <!-- <div class="important-banner">
    <img src="assets/imgs/notification.png">
    <div class="important-message">
        <p>Deal Alert</p>
        Now offering 10 year extended warranty on all new generac generator purchases. Offer expires 12/1 so call us today!
    </div>
</div> -->
  <div class="sub-toolbar" style="text-align: center;margin-top:-.5rem;">
    <div><a href="/services/electrical">Electrical Services</a></div>
    <div><a href="/services/generac/generators">Generator Services</a></div>
    <div><a href="/pay-online">Pay Online</a></div>
  </div>

  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <div class="footer">
    <b>Our Services</b>
    <div class="footer-services" style="padding-left:.5rem;padding-top:.5rem;padding-bottom: .5rem;">
      <div><a class="highlight-link" href="/services/electrical">Electrical Services</a></div>
      <div><a class="highlight-link" href="/services/generac/generators">Generator Services</a></div>
    </div>
    <!-- <b>Located in Northern Indiana</b>
    <p class="italic">Serving Warsaw, Syracuse, Culver, Rochester, Pierceton and surrounding
      areas!</p>
    <br /> -->
    <div style="font-size: 16px">
      <b>Cell</b>: <a class="highlight-link" href="tel:+15745515412">574-551-5412</a>
    </div>
    <div style="font-size: 16px">
      <b>Email</b>: <a class="highlight-link" href="mailto:sales@mdelectricalsolutions.com">sales@mdelectricalsolutions.com</a>
    </div>
    <div style="font-size: 16px">
      <b>Mailing Address</b>: 6190 E 1000 S North Manchester, IN 46962
    </div>
    <br />
    <!-- <div style="font-size: 16px">
      Since 2002, M&D Electrical Solutions, LLC has been serving your electrical, generator, and smart home needs. We are a certified electrician and authorized Generac Power Pro Elite+ dealer serving Culver Indiana, Pierceton Indiana, Rochester Indiana, Syracuse Indiana, Warsaw Indiana and surrounding areas.
    </div> -->
  </div>
</div>