import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-planning-new-home',
	templateUrl: './planning-new-home.component.html',
	styleUrls: [ './planning-new-home.component.scss' ]
})
export class PlanningNewHomeComponent implements OnInit {
	constructor(private titleService: Title, private metaService: Meta) {}

	ngOnInit(): void {
		this.titleService.setTitle('M&D Electrical Solutions, LLC - Planning a New Home');
		this.metaService.addTags([
			{ name: 'keywords', content: 'Electrician, New Home, Planning, New Construction, Electrical, Generators' },
			{
				name: 'description',
				content:
					'Having your home wired correctly the first time is very important and we have the experience and expertise needed to provide you with informative choices during the planning process.'
			},
			{ name: 'robots', content: 'index, follow' }
		]);
	}
}
