<app-content-card>
    <img src="assets/imgs/electrical.png">
    <div>
        <h1><b>Our Electrical Services</b></h1>
        <p>
          We are your best choice for new home and business electrical needs. Whether you're at home or in business, electricity is the most common utility used. Wiring your home or business correctly the first time is very critical. We provide informative choices for all electrical and lighting needs based on our experience and expertise. As the building or remodeling process proceeds, we work continuously with the homeowner(s), local building inspectors, and building contractors to ensure that you are satisfied with the end results.
        </p>
        <b>Residential Services</b>
        <p>
          An 85% retention rate of repeat customers is evidence of the quality of our electrical services! Let us earn your business and deliver you the quality work you deserve!
        </p>
    <div>
        <a class="highlight-link" href="/services/electrical/new-home">Planning a new home?</a>
      </div>
      <div>
        <a class="highlight-link" href="/services/electrical/update-home">Have an existing home but need electrical services?</a>
      </div>

    <b>Commercial Services</b>
    <p>
      You can count on us for all your business electrical needs. We can also help you save money on energy costs by providing energy efficiency advice. From lighting controls to motor controls, as well as new applications for PLCs, we work on everything.
    </p>
    <div>
        <a class="highlight-link" href="/services/electrical/commercial">See what services we can provide your business!</a>
      </div>

    <b>Smart Home Technology</b>
    <p>
      Our home is one of the most critical places to have quality lighting. We perceive our surrounding environment based on the light that enters our eyes. We can enjoy our homes more comfortably with the correct light, enhancing our quality of life. Smart Home Technology allows homeowners to control the lighting in their homes. They can create beautiful environments and control the lights remotely.
    </p>
    </div>
</app-content-card>
