import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ElectricalComponent } from './electrical/electrical.component';
import { GeneratorsComponent } from './generators/generators.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { AffiliationsComponent } from './affiliations/affiliations.component';
import { PlanningNewHomeComponent } from './planning-new-home/planning-new-home.component';
import { ExistingHomeComponent } from './existing-home/existing-home.component';
import { CommercialServicesComponent } from './commercial-services/commercial-services.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { PayOnlineComponent } from './pay-online/pay-online.component';
import { GeneratorResidentialComponent } from './generator-residential/generator-residential.component';
import { GeneratorCommercialComponent } from './generator-commercial/generator-commercial.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { FormCardComponent } from './form-card/form-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		ElectricalComponent,
		GeneratorsComponent,
		ContentCardComponent,
		AffiliationsComponent,
		PlanningNewHomeComponent,
		ExistingHomeComponent,
		CommercialServicesComponent,
		TestimonialsComponent,
		PayOnlineComponent,
		GeneratorResidentialComponent,
		GeneratorCommercialComponent,
		FormCardComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NoopAnimationsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		ReactiveFormsModule,
		FormsModule
	],
	providers: [],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
