import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-electrical',
	templateUrl: './electrical.component.html',
	styleUrls: [ './electrical.component.scss' ]
})
export class ElectricalComponent implements OnInit {
	constructor(private titleService: Title, private metaService: Meta) {}

	ngOnInit(): void {
		this.titleService.setTitle('M&D Electrical Solutions, LLC - Electrical Services');
		this.metaService.addTags([
			{
				name: 'keywords',
				content:
					'Electrician, Existing Home, Planning, Remodeling, Electrical, Generators, New Home, Smart Home'
			},
			{
				name: 'description',
				content:
					'We are your best choice for new home and business electrical needs. Whether you are at home or in business, electricity is the most common utility used.'
			},
			{ name: 'robots', content: 'index, follow' }
		]);
	}
}
