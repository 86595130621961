<app-content-card>
    <img src="assets/imgs/generator-commercial.jpg">
    <div>
        <h1><b>Keep Your Company Going</b></h1>
        <p>Never be without power, Generac Generators will keep your business going!</p>
        <div class="div-list">
            <div>
                <b>5 Year Limited Warranty</b> for automatic standby generators.
            </div>
            <div>
                <b>True Power Technology</b> delivers best-in-class power quality with less than 5 percent total harmonic distortion for clean, smooth operation of sensitive electronics and appliances.
            </div>
            <div>
                Generac generators and engines are <b>engineered and built in the USA</b>
                <br />
                <i>Built in the USA using domestic and foreign parts</i>
            </div>
            <div>
                <b>Generac's G-Force Engine is a purpose-built, pressure-lubricated engine</b> capable of handling the rigors of generator use, resulting in power that's <b>more reliable and requires less routine maintenance</b> than any competitive engine. 
            </div>
            <div>
                Quiet-Test Self-Test Mode runs at a lower RPM for a five or twelve minute test, making generators <b>significantly quieter than other brands while consuming less fuel.</b>
            </div>
            <div>
                <b>24/7/365 Customer Support Team</b> standing by all day, every day from our headquarters in Wisconsin to answer any questions you might have.
            </div>
            <div>
                Our RhinoCoat powder-coated finish helps make <b>corrosion-resistant aluminum perfect for all weather conditions.</b>
            </div>
            <div>
                Generac's Evolution Controller features a <b>multilingual LCD display</b> that allows users to monitor battery status and track maintenance intervals to ensure your generator is always in top operating condition.
            </div>
            <div>
                Mobile Link Remote Monitoring allows you to <b>monitor the status of your generator from anywhere in the world using a smartphone. tablet. or PC.</b> Easily access information, such as th( current operating status and the generator's maintenance schedule. Connect your account to your authorized service dealer for fast, friendly and convenient assistance. (sold separately) 
            </div>
        </div>
    </div>
</app-content-card>