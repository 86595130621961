import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-pay-online',
	templateUrl: './pay-online.component.html',
	styleUrls: [ './pay-online.component.scss' ]
})
export class PayOnlineComponent implements OnInit {
	paymentHandler: any = null;
	isProcessing: boolean = false;
	isProcessingPayment: boolean = false;
	paymentConfirmed: boolean = false;
	paymentFailed: boolean = false;
	paymentKey = 'pk_live_51KlcmwFBAbPq3PWQmNttkLE6VRcrB8Z5U4i5N0vVzcpdhIkFKVX8HwwM0DyGjq1Z3dZLFkQivxm6np0umqNFhcmj00gZpRtWOY';
	baseUrl = 'https://webapi.mdelectricalsolutions.com';

	paymentInformation: FormGroup;

	constructor(private titleService: Title, private metaService: Meta, private httpClient: HttpClient) {}

	ngOnInit() {
		this.makePayment = this.makePayment.bind(this);

		this.titleService.setTitle('M&D Electrical Solutions, LLC - Pay Online');
		this.metaService.addTags([
			{ name: 'keywords', content: 'Online Payment, Secure, Invoice, Warranty Program' },
			{
				name: 'description',
				content:
					'Securely process a payment with us online. Your information is never stored and completely processed by a third party provider that is PCI compliant.'
			},
			{ name: 'robots', content: 'index, follow' }
		]);

		this.paymentInformation = new FormGroup({
			fullname: new FormControl('', Validators.required),
			memo: new FormControl('', Validators.required),
			paymentAmount: new FormControl('', [ Validators.required, Validators.min(10), Validators.max(1500) ])
		});

		this.invokeStripe();
	}

	makePayment() {
		this.isProcessing = true;
		const pInfo = this.paymentInformation.value;

		const self = this;

		const paymentHandler = (<any>window).StripeCheckout.configure({
			key: this.paymentKey,
			locale: 'auto',
			token: function(stripeToken: any) {
				self.isProcessingPayment = true;
				self.processPayment(self.httpClient, self.baseUrl, self.paymentInformation, stripeToken).subscribe(
					(paid) => {
						if (paid) {
							self.paymentConfirmed = true;
						} else {
							self.paymentFailed = true;
						}

						self.isProcessingPayment = false;
					},
					(error) => {
						self.paymentFailed = true;
						self.isProcessingPayment = false;
					}
				);
			}
		});

		paymentHandler.open({
			name: pInfo.fullname,
			description: pInfo.memo,
			amount: pInfo.paymentAmount * 100
		});
	}

	processPayment(http: HttpClient, url: string, paymentInformation: FormGroup, stripeToken: any): Observable<any> {
		const pInfo = paymentInformation.value;

		const params = {
			cardId: stripeToken.card.id,
			tokenId: stripeToken.id,
			emailAddress: stripeToken.email,
			name: pInfo.fullname,
			invoiceMemo: pInfo.memo,
			amount: parseFloat(pInfo.paymentAmount)
		};

		return http.post(`${url}/api/transaction`, params).pipe();
	}

	getPaymentErrorMsg() {
		const paymentAmountCtrl = this.paymentInformation.controls.paymentAmount;

		if (paymentAmountCtrl.hasError('required') || paymentAmountCtrl.hasError('min')) {
			return 'Amount must be greater than $10';
		} else if (paymentAmountCtrl.hasError('max')) {
			return 'We do not accept online payments over $1,500';
		}

		return '';
	}

	invokeStripe() {
		if (!window.document.getElementById('stripe-script')) {
			const script = window.document.createElement('script');
			script.id = 'stripe-script';
			script.type = 'text/javascript';
			script.src = 'https://checkout.stripe.com/checkout.js';
			script.onload = () => {
				this.paymentHandler = (<any>window).StripeCheckout.configure({
					key: this.paymentKey,
					locale: 'auto',
					token: function(stripeToken: any) {}
				});
			};

			window.document.body.appendChild(script);
		}
	}
}
