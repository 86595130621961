<hr style="margin-top: 0rem;" />
<app-content-card>
    <div>
        <h1><b>Secure Payment Processing</b></h1>
        <p>
          The information you provide is processed securely by a third-party company. We do not keep your credit card information.
        </p>
    </div>
    <div *ngIf="isProcessingPayment" style="display: grid;grid-template-columns: 1.75rem auto;">
      <div class="loader">Loading...</div>
      <div>
        <p>
          <b>Processing your payment</b>
          <br />
          Please wait...
        </p>
      </div>
    </div>
    <div *ngIf="paymentConfirmed">
      <b>Success!</b>
      <p>
        Your payment was successfully processed. Thank you!
        <br />
        You should receive a confirmation email!
      </p>
    </div>
    <div *ngIf="paymentFailed">
      <b>Failed!</b>
      <p>
        Sorry but we were unable to process your payment.
        <br />
        You can call us and we can try to process your payment over the phone. Sorry for any inconvenience.
      </p>
    </div>
</app-content-card>
<app-form-card *ngIf="!paymentConfirmed && !paymentFailed" [style.display]="isProcessingPayment ? 'none' : ''">
    <form [formGroup]="paymentInformation">
        <div class="flex-container">
            <div>
              <mat-form-field appearance="fill" hideRequiredMarker="true">
                <mat-label>Full Name</mat-label>
                <input #fullname matInput formControlName="fullname" placeholder="Enter your full name"
                       required>
                       <mat-hint>Required</mat-hint>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="fill" hideRequiredMarker="true">
                <mat-label>Invoice # / Memo</mat-label>
                <input #memo matInput formControlName="memo" placeholder="Enter what the payment is for"
                       required>
                       <mat-hint>Required</mat-hint>
              </mat-form-field>
            </div>
            <div>
                <mat-form-field appearance="fill" hideRequiredMarker="true">
                  <mat-label>Payment Amount</mat-label>
                  <input #memo matInput formControlName="paymentAmount" placeholder="Enter the payment amount"
                         required>
                         <mat-hint>Required</mat-hint>
                    <mat-error *ngIf="paymentInformation.controls.paymentAmount.invalid">{{getPaymentErrorMsg()}}</mat-error>
                </mat-form-field>
              </div>
          </div>
          <div style="display: flex; justify-content: flex-end;padding-top:.25rem;">
            <button mat-raised-button color="primary" [disabled]="!paymentInformation.valid || isProcessing" (click)="makePayment()" style="width:100%;">Continue to Payment</button>
          </div>
          <div>
            <div style="padding-top: .5rem;">
                <img src="assets/imgs/payment.png" style="width: 260px;height:40px;">
            </div>
        </div>
          
        </form>
</app-form-card>