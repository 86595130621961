import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-generator-residential',
	templateUrl: './generator-residential.component.html',
	styleUrls: [ './generator-residential.component.scss' ]
})
export class GeneratorResidentialComponent implements OnInit {
	constructor(private titleService: Title, private metaService: Meta) {}

	ngOnInit(): void {
		this.titleService.setTitle('M&D Electrical Solutions, LLC - Home Generator');
		this.metaService.addTags([
			{ name: 'keywords', content: 'Home Standby Generators, Generac, ElitePro+ Dealer, Authorized, Sales' },
			{
				name: 'description',
				content: 'Generac - They are a brand you can depend on, and know they are built to last!'
			},
			{ name: 'robots', content: 'index, follow' }
		]);
	}
}
