import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
	selector: 'app-generators',
	templateUrl: './generators.component.html',
	styleUrls: [ './generators.component.scss' ]
})
export class GeneratorsComponent implements OnInit {
	constructor(private titleService: Title, private metaService: Meta) {}

	ngOnInit(): void {
		this.titleService.setTitle('M&D Electrical Solutions, LLC - Generators');
		this.metaService.addTags([
			{ name: 'keywords', content: 'Electrician, Generac, Generators, Elite+ Pro Dealer' },
			{
				name: 'description',
				content:
					'We are your local Authorized Generac Power Pro Elite+ Dealer and Service Installer with over 10 years’ experience with Generac products.'
			},
			{ name: 'robots', content: 'index, follow' }
		]);
	}
}
