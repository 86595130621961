import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: [ './app.component.scss' ]
})
export class AppComponent {
	title = 'MDElectricalSolutions';

	constructor(private router: Router) {
		this.setUpAnalytics();
	}

	setUpAnalytics() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				gtag('config', 'G-Z05XV1QNX6', {
					page_path: event.urlAfterRedirects
				});
			}
		});
	}
}
