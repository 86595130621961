<app-content-card>
    <img src="assets/imgs/existing-home.png">
    <div>
        <h1><b>Existing Home Planning</b></h1>
        <p>
            If you need a service upgrade or are anticipating a remodel project, M&D Electrical Solutions, LLC is here to help you get to where you want to go. 
            Our experts are here to help trouble shoot issues, replace fuses and breakers, provide power to pools, spas, hot tubs, 
            add additional outlets and much more.
        </p>
    </div>
    <div>
        <b>Residential Services</b>
        <p>We offer a wide range of existing home services such as but not limited to:</p>
        <ul style="margin-top: -.5rem;">
            <li>Free Estimates</li>
            <li>Service Upgrades</li>
            <li>Remodel Projects</li>
            <li>Whole Home Generators</li>
            <li>Adding Outlets</li>
            <li>Fan Installation</li>
            <li>Pools, Spas, and Suanas</li>
            <li>Landscape Lighting</li>
        </ul>
    </div>
    <div>
        <b>Smart Home Services</b>
        <p>Upgrading an existing home to a smart home has never been easier!</p>
        <ul style="margin-top: -.5rem;">
            <li>Total Lighting Control</li>
            <li>Smart Device Compatable
                <br />
                <small style="padding-left: .5rem;">Amazon Echo, Google Home, Apple HomeKit</small>
            </li>
            <li>Home Automation Devices</li>
            <li>Mobile Supported</li>
        </ul>
    </div>
</app-content-card>