<app-content-card>
    <img src="assets/imgs/affiliations.jpg">
    <div>
        <h1><b>Our Affiliations</b></h1>
        <p>
            When we work together we can achieve more! Below are a list of our affiliations that help make our mission a success!
        </p>
    </div>
    <div style="display: flex; align-content: space-between;align-items: flex-start;">
        <img src="assets/imgs/BANCI.png" style="width:60px;height:60px;padding-right: 1rem;">
        <div>
            <b>BUILDERS ASSOCIATION OF NORTH CENTRAL INDIANA (BANCI)</b><br />
            <span style="margin-bottom: .5rem;">Professional Members. Professional Results.</span>
        <p>
            BANCI is a membership organization comprised of members from various aspects of the building and home industries. It is our goal to assist homeowners in making dream homes a reality.
        </p>
        <div>
            <a class="highlight-link" href="https://www.buildnci.com/">Visit Affiliation</a>
        </div>
        </div>
    </div>
    <div style="display: flex; align-content: space-between;align-items: flex-start;">
        <img src="assets/imgs/PiercetonChamberOfCommerce.png" style="width:60px;height:60px;padding-right: 1rem;">
        <div>
            <b>PIERCETON CHAMBER OF COMMERCE</b>
        <p>
            The Pierceton Chamber of Commerce is committed to being the voice of it's members and the community.
        </p>
        <div>
            <a class="highlight-link" href="http://piercetonchamber.com/">Visit Affiliation</a>
        </div>
        </div>
    </div>
</app-content-card>
