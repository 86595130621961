import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-generator-commercial',
	templateUrl: './generator-commercial.component.html',
	styleUrls: [ './generator-commercial.component.scss' ]
})
export class GeneratorCommercialComponent implements OnInit {
	constructor(private titleService: Title, private metaService: Meta) {}

	ngOnInit(): void {
		this.titleService.setTitle('M&D Electrical Solutions, LLC - Commercial Generator');
		this.metaService.addTags([
			{ name: 'keywords', content: 'Commercial Generators, Generac, ElitePro+ Dealer, Authorized, Sales' },
			{
				name: 'description',
				content: 'Never be without power, Generac Generators will keep your business going!'
			},
			{ name: 'robots', content: 'index, follow' }
		]);
	}
}
