<app-content-card>
    <!-- <img alt="Generac Generator Install" width="100%" src="assets/imgs/home-banner.jpg"> -->
    <img alt="Generac Generator Install" width="100%" src="assets/imgs/md-billboard.png">
   <div>
    <h1><b>About Us</b></h1>
    <!-- <p>
      Founded in 2002 by Mike and Dianna Sellers, M&D Electrical Solutions, LLC provides electrical and energy service solutions. As a company, they have always believed that they should do whatever it takes to meet and exceed our customers' expectations. We place the utmost importance on showing respect, understanding, and responding to customer concerns. Each and every time our team heads to the job site, we strive to provide the highest quality service at the best price. Service areas include Warsaw Indiana and surrounding areas such as Pierceton, Columbia City, Syracuse, Rochester, and Culver.
    </p> -->
    <p>
      M&D Electrical Solutions, LLC is a family-owned business, founded in 2002 by Mike and Dianna Sellers and sold in 2023 to Derrick and Dana Day (Son in law and Daughter). M&D Electrical Solutions, LLC provides electrical and energy service solutions. As a family ran business, we believe that it is a priority to do whatever it takes to meet and exceed our customers' expectations. We place the utmost importance on showing respect, understanding, and responding to customer concerns. Every time our team heads to the job site, we strive to provide the highest quality service at the best price. Service areas include Pierceton, Warsaw North Webster, Syracuse, Milford, Columbia City, Rochester, Culver and other surrounding areas.
    </p>
    <div>
      <a class="highlight-link" href="/reviews">Take a look at what our customers say about us!</a>
    </div>

    <b>Our Mission Statement</b>
    <!-- <p>
      By providing our services, we aim to power up your life.
    </p> -->
    <p>
      To provide value and added service for every customer
    </p>
    <div>
      <a class="highlight-link" href="/affiliations">Visit our affiliates that help make our mission a success!</a>
    </div>

    <!-- <b>Generac Power Pro Elite+ Dealer</b>
    <p>
      It is very important to us that our customers trust us with their power needs. This is why we have strived to become a Power Pro Elite Dealer, in order to ensure that you never lose power again.
      <br />
      <br />
      Generac’s requirements for a Power Pro Dealer:
    </p>
        <ul style="margin-top:-.5rem;">
          <li>Having at least a 4-star customer service rating on Generac.com</li>
          <li>Providing customers with 24 /7 product support</li>
          <li>Employing a dedicated staff of power generation experts</li>
          <li>Obtaining and maintaining Generac factory training and certification for sales and service personnel</li>
          <li>Maintaining an adequate product and parts inventory</li>
          <li>Providing financing options to customers</li>
          <li>Successfully representing the Generac brand for at least three years</li>
        </ul> -->
   </div>
</app-content-card>