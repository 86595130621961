<app-content-card>
    <img src="assets/imgs/reviews.png">
    <div>
        <h1><b>Testimonials</b></h1>
        <p>
            Below are a few testimonials from our customers. We strive to help our customers with respect, understanding, and concerns. 
            Together we have a great working relationship with and are always happy to hear from our customers on how well we did the job.
        </p>
    </div>
    <div>
        <blockquote>
            Just a short note to express our sincere appreciation for the job and coordination effort.  If ever you need a reference you clearly can ask the ()'s.  I am now out of the proverbial "doghouse." At least for now :) 
        </blockquote>
    </div>
    <div>
        <blockquote>
            THANKS SO MUCH FOR YOUR PROMPT SERVICE & QUALITY WORK - WE ARE ENCLOSING THE EXTRA $71.00 - YOU SHOULDN'T HAVE TO PAY FOR IT! WE WILL BE IN TOUCH IN THE FUTURE! THANKS AGAIN - JOHN & JULIE 
        </blockquote>
    </div>
    <div>
        <blockquote>
            Thanks Again Mike for the good work, I [am] so glad to have the generator installed and the outlets safely functioning. Loraine 
        </blockquote>
    </div>
    <div>
        <blockquote>
            I appreciated your efforts to help us in an emergency. If you need a "job well done" reference, I will be glad to give you my positive "thumbs up" Mark 
        </blockquote>
    </div>
    <div>
        <blockquote>
            Thanks, appreciate your good work on this. It has been great working with you. Please feel free to use me [as] a referral. Marv 
        </blockquote>
    </div>
    <div>
        <blockquote>
            We appreciate doing business with you - your professionalism and quality is rarely seen these days - Penny
        </blockquote>
    </div>
    <div>
        <blockquote>
            Thanks Mike. We really appreciate your hard work and eagerness to help out on the project.  Tell Derrick he did a great job as well.  Have a great day.  Best, Thomas
        </blockquote>
    </div>
</app-content-card>