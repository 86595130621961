<app-content-card>
    <img src="assets/imgs/new-home.jpg">
    <div>
        <h1><b>New Home Planning</b></h1>
        <p>
            Let M&D Electrical Solutions, LLC help you with your new home electrical needs.
            Having your home wired correctly the first time is very important and we have the experience and expertise needed 
            to provide you with informative choices during the planning process. Whether you are looking for standard electrical or 
            smart home electrical, we have you covered.
        </p>
    </div>
    <div>
        <b>Residential Services</b>
        <p>We offer a wide range of new home services such as but not limited to:</p>
        <ul style="margin-top: -.5rem;">
            <li>Free Estimates</li>
            <li>New Construction Wiring</li>
            <li>Inspection Corrections</li>
            <li>Whole Home Generators</li>
            <li>Landscape Lighting</li>
        </ul>
    </div>
    <div>
        <b>Smart Home Services</b>
        <p>Newer homes are making the shift to smart lighting and appliances. We are here to help!</p>
        <ul style="margin-top: -.5rem;">
            <li>Total Lighting Control</li>
            <li>Smart Device Compatable
                <br />
                <small style="padding-left: .5rem;">Amazon Echo, Google Home, Apple HomeKit</small>
            </li>
            <li>Home Automation Devices</li>
            <li>Mobile Supported</li>
        </ul>
    </div>
</app-content-card>
