<app-content-card>
    <img src="assets/imgs/commercial-electrical.jpg">
    <div>
        <h1><b>Commercial Services</b></h1>
        <p>
            We've been in the industry for over twenty years. We understand how important it is for your business to be operating at peak performance. 
            Below is a list but not limited to, of services we provide:
        </p>
        <ul style="margin-top: -.5rem;">
            <li>Free Estimates</li>
            <li>Service Upgrades</li>
            <li>Remodel Projects</li>
            <li>Generators</li>
            <li>Landscape Lighting</li>
            <li>Inspection Corrections</li>
            <li>New Construction Wiring</li>
            <li>Code Violations</li>
            <li>Fuses to Breakers</li>
            <li>Motor Controls</li>
            <li>PLC Applications</li>
            <li>Single to Three Phase</li>
        </ul>
    </div>
</app-content-card>
