import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-commercial-services',
	templateUrl: './commercial-services.component.html',
	styleUrls: [ './commercial-services.component.scss' ]
})
export class CommercialServicesComponent implements OnInit {
	constructor(private titleService: Title, private metaService: Meta) {}

	ngOnInit(): void {
		this.titleService.setTitle('M&D Electrical Solutions, LLC - Commercial Services');
		this.metaService.addTags([
			{
				name: 'keywords',
				content: 'Electrician, Commercial Services, Electrical, Generators, Service Upgrades'
			},
			{
				name: 'description',
				content:
					'We have been in the industry for over twenty years. We understand how important it is for your business to be operating at peak performance.'
			},
			{ name: 'robots', content: 'index, follow' }
		]);
	}
}
